<template>
    <div class="container-fluid bg-light">
				<br>
				<h1 class="heading1 text-start">Drive-On Floating Boat Dock Systems</h1>
				<p class=" text-start">Our floating boat docks and lifts are the safest, most user-friendly and convenient marine products
					available on the market today. The innovative lift and buoyant design is maintenance-free, so you
					can spend less time maintaining your equipment and more time out on the water having fun. Jet Dock
					offers specialized <a href="https://www.jetdock.com/pwc-lifts/">jet ski docks</a> and <a
						href="https://www.jetdock.com/products/pontoon-boats.asp">pontoon boat lifts</a>, as well.</p>
				<h3 class=" text-start">Experience the Versatility of a Jet Dock Floating Boat Lift</h3>
				<p class=" text-start">Our product is not simply a dock. The unparalleled Jet Dock floating boat lift system has numerous
					features and performs many tasks. It may be more accurate to say that our system is a floating boat
					lift apparatus with mooring and storage capabilities. In addition to storing your boat, your new
					boat dock lift can be used as a fishing and swimming platform, a floating walkway or even an
					extension to your existing traditional walkway. Jet Dock’s innovative marine designs have been a step
					above the rest for years, allowing watercraft owners to spend more time enjoying their craft and
					being with friends and family out on the water.</p>
				<p class=" text-start"><strong>We classify our lifts in 3 categories:</strong></p>
				<ul class=" text-start">
					<li><a title="Static Boat Docks"
							href="https://www.jetdock.com/boat-lifts/static-lifts.asp"><strong>Static-Engineered Boat
								Docks</strong></a></li>
					<li><a title="Air-Assisted Boat Lifts"
							href="https://www.jetdock.com/boat-lifts/air-assisted.asp"><strong>Air Assisted-Engineered
								Boat Lift Systems</strong></a></li>
					<li><a title="Pontoon Boat Lifts"
							href="https://www.jetdock.com/boat-lifts/air-assisted.asp"><strong>Multi-Hull Boat Dock and
								Boat Lift Systems</strong></a></li>
				</ul>
				<p class=" text-start">Whether you have a Jet Ski, kayak, 12-foot dinghy, powerboat or a 50-foot offshore performance boat,
					our boat dock lift will provide an easy <a
						href="https://www.youtube.com/watch?v=JSNwnptvFIc">drive-on method</a>, <a
						href="https://www.jetdock.com/knowledge-center/maintaining-your-jet-dock.asp">maintenance-free
						construction</a> and superior <a
						href="https://www.jetdock.com/locations/superior-boarding-access.asp">boarding and access</a>,
					all while remaining both modular and portable. Our drive-on floating boat lift is simply the best
					solution for any docking situation.</p>
				<br>

				<h2 class="heading2 text-start"><a href="https://www.jetdock.com/our-best-boat-lifts-for-sale.asp#staticboatlifts"
						title="Static Boat Lifts">Static Floating Boat Lifts</a><a
						href="https://www.jetdock.com/boat-lifts/static-lifts.asp" title="Static Boat Lifts"></a></h2>
				<table width="100%" border="0" cellpadding="10"
					style="border:#CCCCCC 3px solid; background-color:#f9f9f9">
					<tr>
						<td width="234" align="left" valign="top"><a
								href="/our-best-boat-lifts-for-sale.asp#staticboatlifts"><img
									src="https://www.jetdock.com/img/static-icon.jpg" width="234" height="134" border="0"></a></td>
						<td align="left" valign="top">
							<div>
								<div>
									Innovative engineering will get your boat, PWC, Jet Ski or other watercraft completely out of the water. Our static floating boat lift has no moving parts to bog you down with maintenance issues and requires no electricity to lift your boat out of the water.
								</div>
								<div>
								</div>
							</div>
						</td>
					</tr>
				</table>
				<br>
				<br>
				<h2 class="heading2 text-start"><a href="/our-best-boat-lifts-for-sale.asp#airassistedfloatingboatlifts"
						title="Air-Assisted Boat Lifts">Air-Assisted Floating Boat Lifts</a><a
						href="https://www.jetdock.com/boat-lifts/static-lifts.asp" title="Static Boat Lifts"></a></h2>
				<table width="100%" border="0" cellpadding="10"
					style="border:#CCCCCC 3px solid; background-color:#f9f9f9">
					<tr>
						<td width="234" align="left" valign="top"><a
								href="https://www.jetdock.com/our-best-boat-lifts-for-sale.asp#airassistedfloatingboatlifts"><img
									src="https://www.jetdock.com/img/air-icon.jpg" width="234" height="134" border="0"></a></td>
						<td align="left" valign="top">
							<div>
								<div>Our <strong><a
											href="https://www.jetdock.com/our-best-boat-lifts-for-sale.asp#airassistedfloatingboatlifts"
											title="Air Assisted Floating Boat Lifts">air-assisted unit</a></strong>lifts your watercraft completely out of the water via cleverly engineered air chambers located under the floating boat dock. This allows for easy lifting of heavier watercraft.
								</div>
								<div></div>
							</div>
						</td>
					</tr>
				</table>
				<br>
				<br>
				<h2 class="heading2 text-start"><a href="https://www.jetdock.com/our-best-boat-lifts-for-sale.asp#multihulledboatlifts"
						title="Pontoon Boat Lifts">Multi Hull Boat Lifts</a><a
						href="https://www.jetdock.com/boat-lifts/static-lifts.asp" title="Static Boat Lifts"></a></h2>
				<table width="100%" border="0" cellpadding="10"
					style="border:#CCCCCC 3px solid; background-color:#f9f9f9">
					<tr>
						<td width="234" align="left" valign="top"><a
								href="https://www.jetdock.com/our-best-boat-lifts-for-sale.asp#multihulledboatlifts"><img
									src="https://www.jetdock.com/img/multi-hull-icon.jpg" width="234" height="134" border="0"></a></td>
						<td align="left" valign="top">
							<div>
								<div>Our <strong><a
											href="https://www.jetdock.com/our-best-boat-lifts-for-sale.asp#multihulledboatlifts"
											title="Multi Hull Floating Boat Lift Design">floating pontoon watercraft lifting design</a></strong> easily lifts your pontoon boat completely out of the water. Like our other units, our pontoon boat lift has no moving parts and doesn't require electricity to operate.
										</div>
								<div></div>
							</div>
						</td>
					</tr>
				</table>
				<br>
				<a class=" text-start" href="https://jetdock.com/find-your-dock.asp">
					<div class="btn btn-primary"><strong>Not Sure Which Dock You Need?</strong> Use the Dock Finder
						&raquo;<i class="icon-ok icon-white"></i></div>
				</a>

				<h3 class=" text-start">Additional Benefits of a Floating Boat Lift System</h3>
				<p class=" text-start">
					As boats float close together and near pier structures, small electrical currents are produced between the various types of metals. This galvanic process, referred to as electrolysis, can cause pitting to your boat’s underwater metal components, such as the rudder, outdrive and propeller. Jet Dock's line of floating boat dock products was designed to eliminate this type of corrosive damage!</p>
				<p class=" text-start">Corrosion prevention is one of the main benefits of our floating boat lifts and docking systems. Other watercraft are subjected to the corrosiveness of maritime environments, which leads to staining on the hull. In addition, substantial amounts of algae, moss and muck all degrade the overall performance of the watercraft. But with a Jet Dock floating boat lift system, gone are the days of expensive, time-consuming hauling of your craft in and out of the water for cleaning and repainting! It's not difficult to spot the difference between boats that are stored on our floating boat lifts and those that sit in the water full-time.
				</p>
				<p class=" text-start">An additional benefit of our drive-on boat lift is the prevention of impact damage caused by storm waves and wakes. No more rubbing against the dock. Not only will our product protect your boat, but also the mooring structures nearby.
				</p>
				<p class=" text-start">Discover why boaters appreciate our floating boat lift system and what makes our floating boat docks far superior to the competition. Learn more by reading our <a href="https://www.jetdock.com/faq.asp">Boat Lift FAQ</a> page and <a href="https://www.jetdock.com/knowledge-center/">Knowledge Center</a> resource articles.
				</p>
				<!-- <p>Learn more about superior <a href="https://www.jetdock.com/locations/superior-boarding-access.asp"
						title="Easy to Board JetDock or Floating Walkway">drive-on design &amp; easy access</a>.<br />
					Learn <a href="https://www.jetdock.com/what-is-a-boat-lift.asp" title="What is a Boat Lift?">what is
						a boat lift</a>.<br />
					Learn more about our <a href="https://www.bestkayakdocks.com" title="Portable Kayak Docking Design"
						onClick="”pageTracker._trackEvent(‘category’," &lsquo;action&rsquo;,="" &lsquo;kayak="" dock=""
						site&rsquo;);="site&rsquo;);""="">portable kayak docking design</a>.<br />
              Learn <a href=" https://www.jetdock.com/knowledge-center/do-i-need-a-boat-lift.asp"
						title="Why You Need a Boat Lift">why you need a boat lift.</a></p> -->

				<h3 class=" text-start">The Most Important Floating Boat Dock Features To Consider</h3>
				<p class=" text-start">Each of the <a href="https://www.jetdock.com/find-your-dock.asp">floating boat lifts</a> we carry has a standard set of features that you will appreciate. The following are just a few of the many reasons <a href="https://www.jetdock.com/knowledge-center/do-i-need-a-boat-lift.asp">why you need a boat lift</a> as part of your watercraft storage system solution.</p>

				<div class=" text-start">
					<ul>
					<li>Assembled and ready to use</li>
					<li>Durable high-density polyethylene construction</li>
					<li>Patented drive-on ramped design</li>
					<li>Patented modular and changeable configurations</li>
					<li>Non-skid surface</li>
					<li>Expandable walk-around space</li>
					<li>Perimeter tie-off cleats</li>
					<li>Provisions for security systems</li>
					<li>Dry, self-bailing, water-draining deck</li>
					<li>Bow retention winch turret</li>
					<li>Patent-pending submersible deck technology</li>
					<li><a href="https://www.jetdock.com/warranty.asp">Lifetime Limited Warranty</a></li>
					</ul>
				</div>

				<div class=" text-start">
					For more information or help ordering a floating boat dock or lift, <router-link to="/contactform">reach out</router-link>  to our representatives today. Or, give us a call at 1-800-538-3625. 
				</div>
	</div>
</template>